import React from "react"
import {
  FaLinkedin,
  FaGithub,
  FaJsSquare,
  FaUserTie,
  FaBloggerB
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaGithub className="social-icon" title="Github Profile"></FaGithub>,
    url: "https://github.com/Abhishek-Shukla-github",
  },
  {
    id: 2,
    icon: <FaLinkedin className="social-icon" title="Linkedin Profile"></FaLinkedin>,
    url: "https://www.linkedin.com/in/abhishekshukla1999",
  },
  {
    id: 3,
    icon: <FaJsSquare className="social-icon" title="Codewars Profile"></FaJsSquare>,
    url: "https://www.codewars.com/users/shukla_abhishek",
  },
  {
    id: 5,
    icon: <FaUserTie className="social-icon" title="Resume"></FaUserTie>,
    // url: "https://drive.google.com/file/d/10lFqvK-ApaP_GeRo-XqcpxkP0imIGeu2/view",
    url: "https://drive.google.com/file/d/1hFb2eHW_mmB7s9Am5JFLRIiESutlRyeh/view?usp=sharing",
  },
  {
    id: 6,
    icon: <FaBloggerB className="social-icon" title="Hashnode Blog"></FaBloggerB>,
    url: "https://abshukla.hashnode.dev/",
  },
]

export default data
